body.contentmanager-contenttype-homepage {
    .page-main {
        .homepage {
            section {
                + .homepage_services {
                    .service-block {
                        .service-texts {
                            .service-cta {
                                button {
                                    span {
                                        letter-spacing: -0.3px;
                                    }
                                }
                            }
                        }
                    }
                }

                + .homepage_ctifl {
                    .homepage_ctifl_upper {
                        .ctifl_upper_block {
                            .ct-field {
                                font-size: 1rem;
                                max-width: 550px;
                                text-align: justify;
                            }
                        }
                    }
                }
            }
        }
    }
}
