body.aw-et-product-page,
body.contentmanager-contenttype-actu,
body.contentmanager-contenttype-projet,
body.contentmanager-contenttype-edition {
    font-size: 14px;

    .page-main {
        max-width: $container-hd;
    }
    .edition-cover {
        width: 380px;
        height: 535px;
        overflow: hidden;
    }
    .edition-data-panel {
        .summary {
            .summary-title {
            }
            .summary-listing {
                height: 300px;
            }
        }
    }
    .edition-related {
        &-list {
            &-item {
                &.last-related {
                    display: block;
                }
            }
        }
    }
    .actu-article,
    .edition-article {
        &-progress-bar{

        }
        &-header {
            margin-left: calc((-100vw - 40px + #{$container-hd}) / 2);
            padding-left: calc((100vw + 40px - #{$container-hd}) / 2);
            margin-right: calc((-100vw - 40px + #{$container-hd}) / 2);
            padding-right: calc((100vw + 40px - #{$container-hd}) / 2);
        }
    }
}

.edition-progress-bar {
    top: 123px;
}

.page-wrapper:has(.welcome-wrapper) .edition-progress-bar {
    top: calc(123px + #{$welcome-banner-height});
}

body.aw-et-product-page,
body.contentmanager-contenttype-projet,
body.edition-page {
    .edition__header-image {
        width: 1160px;
        height: 290px;
        object-fit: cover;
        margin-bottom: 0;
    }
}

$pouet: 460px;

.edition-publication-container {
    .edition-publication {
        .edition-panel-picture {
            margin-inline: calc(#{$container-hd} / 20);
            max-width: $pouet;
            height: calc(#{$pouet} / 210 * 297);
        }
    }
}
