.mgt-xxs { display: none; }
.mgt-xs  { display: none; }
.mgt-sm  { display: none; }
.mgt-md  { display: none; }
.mgt-lg  { display: none; }
.mgt-hd  { display: block; }

#mgt-developer-toolbar {
    background: #0a6680;
    color: white;
}
