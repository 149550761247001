:root {
    --container-width: #{$container-hd};
}

html, body {
    font-size: $font-size-hd;
}

// Container size
//@include container-sizing($container-hd);
